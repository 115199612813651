import React from "react";
import { Field } from "formik";

export default function SelectField(props) {
    return (
        <Field name={props.name} as="select" className={props.className} multiple={props.multiple ? "multiple" : false}>
            {props.includeNull ? <option key={0} value={props.nullId ? props.nullId : ""}>{props.nullName ? props.nullName : ""}</option> : null}
            {props.options ? props.options.map(option => {
                const id = props.idProperty ? option[props.idProperty] : option.id;
                const value = props.valueProperty ? option[props.valueProperty] : option.name;
                return (
                    <option key={id} value={id}>
                        {value}
                    </option>
                );
            }) : null}
        </Field>
    );
}
