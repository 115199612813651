export const HttpVerb = Object.freeze({
    GET: "GET",
    POST: "POST",
    PUT: "PUT",
    PATCH: "PATCH",
    DELETE: "DELETE"
});

export const MessageType = Object.freeze({
    ALERT: 1,
    TOAST: 2,
    CONSOLE: 3,
});

export const MessageSeverity = Object.freeze({
    INFO: 1,
    SUCCESS: 2,
    WARNING: 3,
    ERROR: 4,
});

export const FilterType = Object.freeze({
    EQUALS: 1,
    CONTAINS: 2,
    STARTS_WITH: 3,
    ENDS_WITH: 4,
    GREATER_THAN: 5,
    GREATER_THAN_OR_EQUAL_TO: 6,
    LESS_THAN: 7,
    LESS_THAN_OR_EQUAL_TO: 8,
});
