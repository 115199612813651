import moment from "moment";

export function formatDate(dateTime) {
    if (isNaN(Date.parse(dateTime))) {
        return "";
    }
    return moment(dateTime).format("M/D/YYYY");
}

export function formatDateTime(dateTime) {
    if (isNaN(Date.parse(dateTime))) {
        return "";
    }
    return moment(dateTime).format("M/D/YYYY h:mm A");
}

export function formatDateTimeLong(dateTime) {
    if (isNaN(Date.parse(dateTime))) {
        return "";
    }
    return moment(dateTime).format("M/D/YYYY h:mm:ss A");
}

export function formatDateIso8601(dateTime) {
    if (isNaN(Date.parse(dateTime))) {
        return "";
    }
    return moment(dateTime).format("YYYY-MM-DD");
}

export function formatCreditCardExpirationDate(dateTime) {
    if (isNaN(Date.parse(dateTime))) {
        return "";
    }
    return moment(dateTime).format("MM/YYYY");
}

export function getFormattedErrors(response) {
    let message = "";

    if (response && response.status && response.status !== 200) {
        if (response.errors) {
            if (!Array.isArray(response.errors)) {
                Object.getOwnPropertyNames(response.errors).forEach(propertyName => {
                    response.errors[propertyName].forEach(error => {
                        message += `; ${error}`;
                    });
                });
            } else {
                response.errors.forEach(error => {
                    message += `; ${error}`;
                });
            }
            return message.substring(1);
        }

        return "Unknown error.";
    }

    return message;
}

export function mapIntIds(values, ids) {
    if (!ids) {
        ids = [];
        Object.getOwnPropertyNames(values).forEach(propertyName => {
            if (propertyName.endsWith("Id")) {
                ids.push(propertyName);
            }
        });
    }

    ids.forEach(id => {
        if (values[id] === undefined || values[id] === null) {
            values[id] = null;
        } else {
            values[id] = parseInt(values[id], 10);
        }
    });

    return values;
}

export function inputClass(isInvalid = false) {
    const formClass = "form-control";
    return isInvalid ?
        `${formClass} is-invalid` :
        formClass;
}

export const objectIdsToInt = obj => {
    var newObject = obj;

    Object.getOwnPropertyNames(newObject).forEach(propertyName => {
        if (propertyName.endsWith("Id")) {
            const intValue = parseInt(newObject[propertyName], 10);

            if (intValue == newObject[propertyName]) {
                newObject[propertyName] = intValue;
            }
        }
    });

    return newObject;
};
