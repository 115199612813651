import React, { useState, useEffect } from "react";
import authService from '../api-authorization/AuthorizeService';
import { AuthenticationResultStatus } from "../api-authorization/AuthorizeService";

export default function SmsQueueCallToAction (props) {
    const [subscription, setSubscription] = useState(null);
    const [identity, setIdentity] = useState({ isAuthenticated: false, userName: null });

    useEffect(() => {
        setSubscription(authService.subscribe(() => populateState()));
        populateState();

        //return () => {
        //    authService.unsubscribe(subscription);
        //};
    }, []);

    const populateState = () => {
        (async () => {
            const [isAuthenticated, user] = await Promise.all([authService.isAuthenticated(), authService.getUser()])

            if (user.role !== "Administrator") {
                (async () => {
                    const state = { returnUrl: "/Identity/Account/Login" };
                    const result = await authService.signOut(state);
                    if (result.status === AuthenticationResultStatus.Success) {
                        window.location.replace("/Identity/Account/Login");
                    }
                    return;
                })();
            }

            setIdentity({
                isAuthenticated,
                userName: user && user.name,
                firstName: user && user.given_name,
                lastName: user && user.family_name,
                email: user && user.email,
                phone: user && user.phone_number,
                role: user && user.role,
            });
        })();
    };

    return (
        <>
            <p>
                <a href="/participation-report" className="btn btn-primary">Participation Report</a>
            </p>

            <p>
                <a href="/send-fundraiser-call-to-action" className="btn btn-primary">Send Fundraiser Reminder</a>
            </p>

            <p style={{ display: "none" }}>
                <a href="/send-donor-call-to-action" className="btn btn-primary">Send Donor Appeal</a>
            </p>
        </>
    );
}
