import React from "react";
import CheerWall from "../components/CheerWall";

export default function DonationConfirmation (props) {
    const FacebookButton = (props) => {
        return (
            <iframe title="Facebook" src="https://www.facebook.com/plugins/share_button.php?href=https%3A%2F%2Frrhs-band.com&layout&size&width=77&height=20&appId" width={77} height={20} style={{ border: "none", overflow: "hidden" }} scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>
        );
    };

    return (
        <>
            <div>
                <p>
                    Thank you for supporting the RRHS band!
                </p>

                <p>
                    A confirmation e-mail and receipt has been sent.
                </p>

                <p>
                    Please spread the word!  You can share our link with family and friends, or share on Facebook using the button below.
                </p>

                <p>
                    <FacebookButton />
                </p>
            </div>

            <CheerWall />

            <br />
        </>
    );
}
