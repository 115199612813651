import React, { useState, useEffect } from 'react';
import { Collapse, Container, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import { LoginMenu } from '../api-authorization/LoginMenu';
import './NavMenu.css';
import { NavHashLink } from "react-router-hash-link";
import authService from '../api-authorization/AuthorizeService';

export function NavMenu(props) {
    const [subscription, setSubscription] = useState(null);
    const [identity, setIdentity] = useState({ isAuthenticated: false, userName: null });
    const [collapsed, setCollapsed] = useState(true);

    useEffect(() => {
        setSubscription(authService.subscribe(() => populateState()));
        populateState();

        //return () => {
        //    authService.unsubscribe(subscription);
        //};
    }, []);

    const toggleNavbar = (event) => {
        const isCollapsed = collapsed;
        setCollapsed(!isCollapsed);
    };

    const authenticatedStyle = {
        display: identity.isAuthenticated ? "" : "none"
    };

    const populateState = () => {
        (async () => {
            const [isAuthenticated, user] = await Promise.all([authService.isAuthenticated(), authService.getUser()])
            setIdentity({
                isAuthenticated,
                userName: user && user.name,
                firstName: user && user.given_name,
                lastName: user && user.family_name,
                email: user && user.email,
                phone: user && user.phone_number,
                role: user && user.role,
            });
        })();
    };

    return (
        <header>
            <Navbar className="navbar-expand-sm navbar-toggleable-sm fixed-top ng-white border-bottom box-shadow mb-3" light style={{ backgroundColor: "white" }}>
                <Container>
                    <NavbarBrand tag={Link} to="/" style={{ whiteSpace: "nowrap" }}>RRHS Band Fundraiser</NavbarBrand>
                    <NavbarToggler onClick={toggleNavbar} className="mr-2" />
                    <Collapse className="d-sm-inline-flex flex-sm-row-reverse" isOpen={!collapsed} navbar>
                        <ul className="navbar-nav flex-grow" style={{ whiteSpace: "nowrap" }}>
                            <NavItem>
                                <NavLink tag={Link} className="text-dark" to="/" style={{ display: "none" }}>Home</NavLink>
                            </NavItem>
                            {identity.isAuthenticated ?
                                <>
                                    <NavItem>
                                        <NavLink tag={Link} className="text-dark" to="/contact-page" style={authenticatedStyle}>My Page</NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavHashLink smooth to="/contacts#email-addresses" className="nav-link text-dark" activeClassName="selected" style={authenticatedStyle}>E-Mail Addresses</NavHashLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavHashLink smooth to="/contacts#phone-numbers" className="nav-link text-dark" activeClassName="selected" style={authenticatedStyle}>Phone Numbers</NavHashLink>
                                    </NavItem>
                                </> : null}
                            <NavItem>
                                <NavHashLink smooth to="/privacy-policy" className="nav-link text-dark" activeClassName="selected">Privacy Policy</NavHashLink>
                            </NavItem>
                            <NavItem>
                                <NavHashLink smooth to="/terms-of-use" className="nav-link text-dark" activeClassName="selected">Terms of Use</NavHashLink>
                            </NavItem>
                            <LoginMenu />
                        </ul>
                    </Collapse>
                </Container>
            </Navbar>
        </header>
    );
}
