import React, { useState, useEffect, useRef, useCallback } from "react";
import authService from '../api-authorization/AuthorizeService';
import LoadingOverlay from "react-loading-overlay";
import { NavHashLink } from "react-router-hash-link";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import JoditEditor from "jodit-react";
import Message from "../components/Message";
import * as enums from "../core/enums";

library.add(faTrashAlt);

export default function ContactPage(props) {
    const joditEditor = useRef(null);
    const [isLoading, setIsLoading] = useState(false);
    const [message, setMessage] = useState("");
    const [subscription, setSubscription] = useState(null);
    const [identity, setIdentity] = useState({ isAuthenticated: false, userName: null });
    const [content, setContent] = useState("");
    const [url, setUrl] = useState("");

    useEffect(() => {
        setSubscription(authService.subscribe(() => populateState()));
        populateState();

        setIsLoading(true);
        (async () => {
            const token = await authService.getAccessToken();

            await fetch("/api/contacts/page",
                {
                    method: "GET",
                    headers: {
                        "Accept": "application/json",
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${token}`
                    }
                })
                .then(response => response.text())
                .then(text => text.length ? JSON.parse(text) : {})
                .then((response) => {
                    if (response.errors) {
                        console.error(response);
                        return;
                    }

                    setContent(response.content);
                    setUrl(response.pageUrl);
                })
                .catch(error => {
                    console.error(error);
                });

            setIsLoading(false);
        })();

        //return () => {
        //    authService.unsubscribe(subscription);
        //};
    }, []);

    const populateState = () => {
        (async () => {
            const [isAuthenticated, user] = await Promise.all([authService.isAuthenticated(), authService.getUser()])
            setIdentity({
                isAuthenticated,
                userName: user && user.name,
                firstName: user && user.given_name,
                lastName: user && user.family_name,
                email: user && user.email,
                phone: user && user.phone_number,
                role: user && user.role,
            });
        })();
    };

    const updateContent = (event) => {
        setContent(event.target.value);
    };

    const onUpdateContentClick = useCallback(() => {
        //setIsLoading(true);

        let model = { content, contactId: 0, contactPageId: 0 };
        (async () => {
            const token = await authService.getAccessToken();
            await fetch("/api/contacts/page",
                {
                    method: "PUT",
                    headers: {
                        "Accept": "application/json",
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${token}`
                    },
                    body: JSON.stringify(model)
                })
                .then(response => response.text())
                .then(text => text.length ? JSON.parse(text) : {})
                .then((response) => {
                    if (response.errors) {
                        console.error(response);
                        return;
                    }

                    setMessage("Page content has been updated.");
                    setContent(response.content);
                })
                .catch(error => {
                    console.error(error);
                });

            //setIsLoading(false);
        })();
    }, [content]);

    const messageProps = {
        severity: enums.MessageSeverity.SUCCESS,
        types: [enums.MessageType.TOAST],
    };

    const loadingOverlayStyles = {
        overlay: (base) => ({
            ...base,
            background: "rgba(255, 255, 255, 0.5)"
        }),
        spinner: (base) => ({
            ...base,
            width: "60px",
            "& svg circle": { stroke: "rgba(0, 0, 0, 1.0)" }
        })
    }

    // TODO: EFR: Pull placeholder from campaign default message.

    //buttons: ['source', '|', 'bold', 'italic', '|', 'ul', 'ol', '|', 'font', 'fontsize', 'brush', 'paragraph', '|', 'image', 'video', 'table', 'link', '|', 'left', 'center', 'right', 'justify', '|', 'undo', 'redo', '|', 'hr', 'eraser', 'fullsize'],
    const joditConfig = {
        readonly: false,
        buttons: ['source', '|', 'bold', 'italic', '|', 'ul', 'ol', '|', 'font', 'fontsize', 'brush', 'paragraph', '|', 'left', 'center', 'right', 'justify', '|', 'undo', 'redo', '|', 'hr', 'eraser', 'fullsize'],
        toolbarAdaptive: false,
        placeholder: "Please support the band and help me reach my $500 fundraising goal.",
    };

    return (
        <LoadingOverlay active={isLoading} spinner styles={loadingOverlayStyles}>
            <Message message={message} {...messageProps} />

            <div className="form-group">
                <label>Your Message to Donors</label>
                <JoditEditor ref={JoditEditor} value={content} config={joditConfig} onBlur={newContent => setContent(newContent)} onChange={newContent => { }} />
            </div>

            <div className="form-group">
                <button className="btn btn-primary" onClick={onUpdateContentClick}>Save</button>
            </div>

            <div className="form-group">
                Try it out:  <a href={url} target="_blank">{url}</a>
            </div>
        </LoadingOverlay>
    );
}
