import React, { useState, useEffect } from "react";
import LoadingOverlay from "react-loading-overlay";
import "./Fundraiser.css";
import SelectField from "../components/SelectField";
import { Formik, Field, Form, ErrorMessage } from "formik";
import Message from "../components/Message";
import * as enums from "../core/enums";
import { getFormattedErrors, inputClass, objectIdsToInt } from "../core/core";
import CreditCardInput from "react-credit-card-input";
import "./Donate.css";

export default function Donate(props) {
    let initialValues = {
        contactId: 0,
        amount: 0,
        creditCardNumber: "",
        creditCardExpirationMonth: 0,
        creditCardExpirationYear: 0,
        creditCardVerificationCode: "",
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        streetAddress: "",
        locality: "",
        regionId: 11,
        postalCode: "",
        countryId: 235,
        message: "",
        anonymous: false,
    };

    const [isLoading, setIsLoading] = useState(false);
    const [message, setMessage] = useState("");
    const [baseUrl, setBaseUrl] = useState("");
    const [formValues, setFormValues] = useState(initialValues);
    const [viewModel, setViewModel] = useState({});
    const [code, setCode] = useState(null);
    const [regions, setRegions] = useState([]);
    const [countries, setCountries] = useState([]);
    const [creditCardTypes, setCreditCardTypes] = useState([]);
    const [creditCardExpirationMonths, setCreditCardExpirationMonths] = useState([]);
    const [creditCardExpirationYears, setCreditCardExpirationYears] = useState([]);
    const [cardNumber, setCardNumber] = useState("");
    const [cardExpiration, setCardExpiration] = useState("");
    const [cardCvc, setCardCvc] = useState("");

    useEffect(() => {
        setIsLoading(true);

        const urlSearchParams = new URLSearchParams(window.location.search);
        const params = Object.fromEntries(urlSearchParams.entries());
        const keyNames = Object.keys(params);

        let code = "";
        if (keyNames.length === 2) {
            code = keyNames[0];
            setCode(keyNames[0]);
        }

        initialValues.amount = Number(params.a);

        let url = "";
        if (code) {
            url = `/api/donations/${encodeURIComponent(code)}`;
            setBaseUrl(`donate?${code}&`);
        } else {
            url = "/api/donations";
            setBaseUrl(`donate?`);
        }

        (async () => {
            await fetch(url,
                {
                    method: "GET",
                    headers: {
                        "Accept": "application/json",
                        "Content-Type": "application/json"
                    }
                })
                .then(response => response.text())
                .then(text => text.length ? JSON.parse(text) : {})
                .then((response) => {
                    if (response.errors) {
                        setMessage(getFormattedErrors(response));
                        console.error(response);
                        return;
                    }

                    setViewModel(response);
                    initialValues.contactId = response.contactId;
                    initialValues.firstName = response.firstName ?? "";
                    initialValues.lastName = response.lastName ?? "";
                    initialValues.email = response.email ?? "";
                    initialValues.phone = response.phone ?? "";

                })
                .catch(error => {
                    setMessage(error);
                    console.error(error);
                });

            await fetch("/api/payments/credit-card-types",
                {
                    method: "GET",
                    headers: {
                        "Accept": "application/json",
                        "Content-Type": "application/json"
                    }
                })
                .then(response => response.text())
                .then(text => text.length ? JSON.parse(text) : {})
                .then(response => {
                    if (response.errors) {
                        setMessage(getFormattedErrors(response));
                        return;
                    }
                    setCreditCardTypes(response);
                })
                .catch(error => {
                    setMessage(error);
                    console.error(error);
                });

            await fetch("/api/payments/credit-card-expiration-months",
                {
                    method: "GET",
                    headers: {
                        "Accept": "application/json",
                        "Content-Type": "application/json"
                    }
                })
                .then(response => response.text())
                .then(text => text.length ? JSON.parse(text) : {})
                .then(response => {
                    if (response.errors) {
                        setMessage(getFormattedErrors(response));
                        return;
                    }
                    setCreditCardExpirationMonths(response);
                })
                .catch(error => {
                    setMessage(error);
                    console.error(error);
                });

            await fetch("/api/payments/credit-card-expiration-years",
                {
                    method: "GET",
                    headers: {
                        "Accept": "application/json",
                        "Content-Type": "application/json"
                    }
                })
                .then(response => response.text())
                .then(text => text.length ? JSON.parse(text) : {})
                .then(response => {
                    if (response.errors) {
                        setMessage(getFormattedErrors(response));
                        return;
                    }
                    setCreditCardExpirationYears(response);
                })
                .catch(error => {
                    setMessage(error);
                    console.error(error);
                });

            await fetch("/api/regions",
                {
                    method: "GET",
                    headers: {
                        "Accept": "application/json",
                        "Content-Type": "application/json"
                    }
                })
                .then(response => response.text())
                .then(text => text.length ? JSON.parse(text) : {})
                .then((response) => {
                    if (response.errors) {
                        setMessage(getFormattedErrors(response));
                        console.error(response);
                        return;
                    }

                    setRegions(response.map(record => {
                        const model = record;
                        model.id = record.regionId;
                        return model;
                    }));
                })
                .catch(error => {
                    setMessage(error);
                    console.error(error);
                });

            await fetch("/api/countries",
                {
                    method: "GET",
                    headers: {
                        "Accept": "application/json",
                        "Content-Type": "application/json"
                    }
                })
                .then(response => response.text())
                .then(text => text.length ? JSON.parse(text) : {})
                .then((response) => {
                    if (response.errors) {
                        setMessage(getFormattedErrors(response));
                        console.error(response);
                        return;
                    }

                    setCountries(response.map(record => {
                        const model = record;
                        model.id = record.countryId;
                        return model;
                    }));
                })
                .catch(error => {
                    setMessage(error);
                    console.error(error);
                });

            setIsLoading(false);
        })();
    }, []);

    const validate = values => {
        setMessage("");

        const errors = {};

        values.creditCardNumber = cardNumber.replace(/ /g, "");

        if (!cardNumber) {
            errors.creditCardNumber = "Credit card number is required.";
            setMessage("Credit card number is required.");
        }

        if (values.creditCardNumber.startsWith("3")) {
            errors.creditCardNumber = "American Express is not accepted.";
            setMessage("American Express is not accepted.");
        }

        if (cardExpiration && cardExpiration.includes("/")) {
            var expirationArray = cardExpiration.split("/");
            values.creditCardExpirationMonth = expirationArray[0].trim();
            values.creditCardExpirationYear = `20${expirationArray[1].trim()}`;
        }
        else
        {
            errors.creditCardExpirationMonth = "Credit card expiration date is required.";
            setMessage("Credit card expiration date is required.");
        }

        values.creditCardVerificationCode = cardCvc;

        if (!cardCvc) {
            errors.creditCardVerificationCode = "Credit card verification code is required.";
            setMessage("Credit card verification code is required.");
        }

        if (!values.firstName) {
            errors.firstName = "First name is required.";
        }

        if (!values.lastName) {
            errors.lastName = "Last name is required.";
        }

        if (!values.email) {
            errors.email = "E-mail address is required.";
        }

        if (values.phone.length !== 10 || values.phone.includes(".") || isNaN(values.phone)) {
            errors.phone = "Invalid mobile phone number.";
        }

        if (!values.streetAddress) {
            errors.streetAddress = "Street address is required.";
        }

        if (!values.locality) {
            errors.locality = "City is required.";
        }

        if (!values.regionId) {
            errors.regionId = "State is required.";
        }

        if (!values.postalCode) {
            errors.postalCode = "Postal code is required.";
        } else if (values.postalCode.length !== 5 || values.postalCode.includes(".") || isNaN(values.postalCode)) {
            errors.postalCode = "Invalid postal code.";
        }

        if (!values.countryId) {
            errors.countryId = "Country is required.";
        }

        if (!values.amount || values.amount < 1) {
            errors.amount = "Minimum donation amount is $20.00.";
        }

        return errors;
    };

    const onSubmit = (values, { setSubmitting }) => {
        values = objectIdsToInt(values);
        setIsLoading(true);
        setMessage("");
        setSubmitting(true);
        (async () => {
            await fetch("/api/donations",
                {
                    method: "POST",
                    headers: {
                        "Accept": "application/json",
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify(values)
                })
                .then(response => response.text())
                .then(text => text.length ? JSON.parse(text) : {})
                .then((response) => {
                    if (response.errors) {
                        setMessage(getFormattedErrors(response));
                        console.error(response);
                        return;
                    }

                    if (!response.success) {
                        setSubmitting(false);
                        setMessage(response.message);
                    } else {
                        window.open(`/donation-confirmation`, "_self");
                    }
                })
                .catch(error => {
                    setSubmitting(false);
                    setMessage(error);
                    console.error(error);
                });

            setIsLoading(false);
        })();
    };

    const loadingOverlayStyles = {
        paddingTop: "4.5rem",
        minHeight: "75rem",
        overlay: (base) => ({
            ...base,
            background: "rgba(255, 255, 255, 0.5)"
        }),
        spinner: (base) => ({
            ...base,
            width: "60px",
            "& svg circle": { stroke: "rgba(0, 0, 0, 1.0)" }
        })
    };

    const messageProps = {
        severity: enums.MessageSeverity.ERROR,
        types: [enums.MessageType.ALERT],
    };

    const handleCardNumberChange = (e) => {
        setCardNumber(e.target.value);
    };

    const handleCardExpirationChange = (e) => {
        setCardExpiration(e.target.value);
    };

    const handleCardCvcChange = (e) => {
        setCardCvc(e.target.value);
    };

    return (
        <LoadingOverlay active={isLoading} spinner styles={loadingOverlayStyles}>
            <Message message={message} {...messageProps} />

            <div className="pb-3">
                {creditCardTypes.map(creditCardType => {
                    return (
                        <i key={creditCardType.creditCardTypeId} className={`${creditCardType.fontAwesomeIconClassString} fa-3x mr-2`} style={{ color: "#343a40" }} />
                    );
                })}
            </div>

            <Formik
                enableReinitialize="true"
                initialValues={formValues}
                onSubmit={onSubmit}
                validate={validate}>

                {({ touched, errors, isSubmitting }) => {
                    return (
                        <Form>
                            <Field name="contactId" type="hidden" />
                            <Field name="creditCardNumber" type="hidden" />
                            <Field name="creditCardExpirationMonth" type="hidden" />
                            <Field name="creditCardExpirationYear" type="hidden" />
                            <Field name="creditCardVerificationCode" type="hidden" />

                            <div className="form-group">
                                <label htmlFor="amount">Amount ($ USD)</label>
                                <Field name="amount" type="number" className={inputClass(touched.amount && errors.amount) + " w-25"} min={1} max={2500} />
                                <div className="invalid-feedback"><ErrorMessage name="amount" /></div>
                            </div>

                            <div className="form-group">
                                <label htmlFor="message">Message to {viewModel.contactId > 0 ? "Student" : "Group"}</label>
                                <Field name="message" component="textarea" rows="2" type="text" className={inputClass(touched.lastName && errors.lastName)} maxLength={120} />
                                <small className="form-text text-muted">Enter up to 120 characters including spaces.  Your message will appear on our public web site.</small>
                            </div>

                            <div className="form-group form-check">
                                <Field id="anonymous" name="anonymous" type="checkbox" className="form-check-input" />
                                <label htmlFor="anonymous" className="form-check-label">I wish to remain anonymous.</label>
                                <small className="form-text text-muted">Your name will appear next to your message on our public web site unless you select the checkbox above.</small>
                            </div>

                            <div className="form-group">
                                <label>Credit Card</label><br />
                                <CreditCardInput
                                    cardNumberInputProps={{ value: cardNumber, onChange: handleCardNumberChange }}
                                    cardExpiryInputProps={{ value: cardExpiration, onChange: handleCardExpirationChange }}
                                    cardCVCInputProps={{ value: cardCvc, onChange: handleCardCvcChange }}
                                    fieldClassName="form-control" />
                            </div>

                            <div className="form-group">
                                <label htmlFor="firstName">First Name</label>
                                <Field name="firstName" type="text" className={inputClass(touched.firstName && errors.firstName) + " w-50"} required />
                                <div className="invalid-feedback"><ErrorMessage name="firstName" /></div>
                            </div>

                            <div className="form-group">
                                <label htmlFor="lastName">Last Name</label>
                                <Field name="lastName" type="text" className={inputClass(touched.lastName && errors.lastName) + " w-50"} required />
                                <div className="invalid-feedback"><ErrorMessage name="lastName" /></div>
                            </div>

                            <div className="form-group">
                                <label htmlFor="email">E-Mail Address</label>
                                <Field name="email" type="email" className={inputClass(touched.email && errors.email) + " w-50"} maxLength="255" required />
                                <div className="invalid-feedback"><ErrorMessage name="email" /></div>
                            </div>

                            <div className="form-group">
                                <label htmlFor="phone">Mobile Phone</label>
                                <Field name="phone" type="text" className={inputClass(touched.phone && errors.phone) + " w-50"} placeholder="9999999999" minLength="10" maxLength="10" />
                                <div className="invalid-feedback"><ErrorMessage name="phone" /></div>
                            </div>

                            <div className="form-group">
                                <label htmlFor="streetAddress">Address</label>
                                <Field name="streetAddress" type="text" className={inputClass(touched.streetAddress && errors.streetAddress) + " w-50"} maxLength="100" required />
                                <div className="invalid-feedback"><ErrorMessage name="streetAddress" /></div>
                            </div>

                            <div className="form-group">
                                <label htmlFor="locality">City</label>
                                <Field name="locality" type="text" className={inputClass(touched.locality && errors.locality) + " w-50"} minLength="2" maxLength="100" required />
                                <div className="invalid-feedback"><ErrorMessage name="locality" /></div>
                            </div>

                            <div className="form-group">
                                <label htmlFor="regionId">State</label>
                                <SelectField name="regionId" options={regions} valueProperty="abbreviation" includeNull={regions.length === 1 ? false : true} className={inputClass(touched.regionId && errors.regionId) + " w-20"} />
                                <div className="invalid-feedback"><ErrorMessage name="regionId" /></div>
                            </div>

                            <div className="form-group">
                                <label htmlFor="postalCode">Postal Code</label>
                                <Field name="postalCode" type="text" className={inputClass(touched.postalCode && errors.postalCode) + " w-25"} minLength="5" maxLength="5" required />
                                <div className="invalid-feedback"><ErrorMessage name="postalCode" /></div>
                            </div>

                            <div className="form-group">
                                <label htmlFor="countryId">Country</label>
                                <SelectField name="countryId" options={countries} valueProperty="isoCode2" includeNull={countries.length === 1 ? false : true} className={inputClass(touched.countryId && errors.countryId) + " w-20"} />
                                <div className="invalid-feedback"><ErrorMessage name="countryId" /></div>
                            </div>

                            <div className="form-group">
                                <button type="submit" className="btn btn-primary mr-2" disabled={isSubmitting}>
                                    {isSubmitting ? "Submitting..." : "Submit"}
                                </button>
                            </div>
                        </Form>
                    );
                }}

            </Formik>
        </LoadingOverlay>
    );
}
