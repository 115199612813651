import React, { useState, useEffect } from "react";
import authService from '../api-authorization/AuthorizeService';
import LoadingOverlay from "react-loading-overlay";
import { AuthenticationResultStatus } from "../api-authorization/AuthorizeService";

const currencyFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
});

const percentFormatter = new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 1,
});

export default function ParticipationReport (props) {
    const [isLoading, setIsLoading] = useState(false);
    const [subscription, setSubscription] = useState(null);
    const [identity, setIdentity] = useState({ isAuthenticated: false, isAdministrator: false, userName: null });
    const [records, setRecords] = useState([]);
    const [campaign, setCampaign] = useState({});
    const [sortType, setSortType] = useState("raised");

    useEffect(() => {
        setSubscription(authService.subscribe(() => populateState()));
        populateState();

        refreshReport();

        //return () => {
        //    authService.unsubscribe(subscription);
        //};
    }, []);

    const refreshReport = (st) => {
        if (st) {
            setSortType(st);
        }
        setIsLoading(true);
        (async () => {
            const token = await authService.getAccessToken();

            await fetch("/api/donations/",
                {
                    method: "GET",
                    headers: {
                        "Accept": "application/json",
                        "Content-Type": "application/json"
                    }
                })
                .then(response => response.text())
                .then(text => text.length ? JSON.parse(text) : {})
                .then((response) => {
                    if (response.errors) {
                        console.error(response);
                        return;
                    }

                    setCampaign(response);
                })
                .catch(error => {
                    console.error(error);
                });

            await fetch(`/api/reports/participation/${st}`,
                {
                    method: "GET",
                    headers: {
                        "Accept": "application/json",
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${token}`
                    }
                })
                .then(response => response.text())
                .then(text => text.length ? JSON.parse(text) : {})
                .then((response) => {
                    if (response.errors) {
                        console.error(response);
                        return;
                    }

                    setRecords(response);
                })
                .catch(error => {
                    console.error(error);
                });

            setIsLoading(false);
        })();
    };

    const populateState = () => {
        (async () => {
            const [isAuthenticated, user] = await Promise.all([authService.isAuthenticated(), authService.getUser()])

            if (user.role !== "Administrator") {
                (async () => {
                    const state = { returnUrl: "/Identity/Account/Login" };
                    const result = await authService.signOut(state);
                    if (result.status === AuthenticationResultStatus.Success) {
                        window.location.replace("/Identity/Account/Login");
                    }
                    return;
                })();
            }

            setIdentity({
                isAuthenticated,
                userName: user && user.name,
                firstName: user && user.given_name,
                lastName: user && user.family_name,
                email: user && user.email,
                phone: user && user.phone_number,
                role: user && user.role,
            });
        })();
    };

    const loadingOverlayStyles = {
        overlay: (base) => ({
            ...base,
            background: "rgba(255, 255, 255, 0.5)"
        }),
        spinner: (base) => ({
            ...base,
            width: "60px",
            "& svg circle": { stroke: "rgba(0, 0, 0, 1.0)" }
        })
    }

    return (
        <LoadingOverlay active={isLoading} spinner styles={loadingOverlayStyles}>
            <div className="form-group">
                <a href="/admin" className="btn btn-primary">&lt; Back to Admin</a>
            </div>

            <table className="table" style={{ width: "400px" }}>
                <tbody>
                    <tr>
                        <td><strong>Campaign Goal:</strong></td>
                        <td style={{ textAlign: "right" }}>{campaign.campaignGoal ? currencyFormatter.format(campaign.campaignGoal) : "Loading..."}</td>
                    </tr>
                    <tr>
                        <td><strong>Campaign Raised:</strong></td>
                        <td style={{ textAlign: "right" }}>{campaign.campaignGoal ? currencyFormatter.format(campaign.campaignRaised) : "Loading..."}</td>
                    </tr>
                    <tr>
                        <td><strong>Campaign Percent of Goal Raised:</strong></td>
                        <td style={{ textAlign: "right" }}>{campaign.campaignGoal ? `${(campaign.campaignRaised / campaign.campaignGoal) * 100}%` : "Loading..."}</td>
                    </tr>
                    <tr>
                        <td><strong>Total E-Mail Addresses:</strong></td>
                        <td style={{ textAlign: "right" }}>{records.reduce(function (prev, cur) { return prev + cur.smtpAddressCount; }, 0)}</td>
                    </tr>
                    <tr>
                        <td><strong>Total Phone Numbers:</strong></td>
                        <td style={{ textAlign: "right" }}>{records.reduce(function (prev, cur) { return prev + cur.phoneNumberCount; }, 0)}</td>
                    </tr>
                </tbody>
            </table>
            <br />
            <button className="btn btn-primary mr-3" onClick={() => refreshReport("raised")}>Sort By Percent Raised</button>
            <button className="btn btn-primary" onClick={() => refreshReport("donors")}>Sort By Donors Added</button>
            <br />
            <br />
            <table className="table">
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>E-Mail Address</th>
                        <th>Phone</th>
                        <th style={{ textAlign: "right" }}>% Raised</th>
                        <th style={{ textAlign: "right" }}>Raised</th>
                        <th style={{ textAlign: "right" }}>Goal</th>
                        <th style={{ textAlign: "right" }}>E-Mails</th>
                        <th style={{ textAlign: "right" }}>Phones</th>
                    </tr>
                </thead>
                <tbody>
                    {records.map(record => {

                        let rowClass = "";

                        if (sortType === "donors") {
                            if (record.smtpAddressCount >= 20 && record.phoneNumberCount >= 10) {
                                rowClass = "table-success";
                            } else if (record.smtpAddressCount >= 20 || record.phoneNumberCount >= 10) {
                                rowClass = "table-warning";
                            } else if (record.smtpAddressCount === 0 || record.phoneNumberCount === 0) {
                                rowClass = "table-danger";
                            } else if (record.smtpAddressCount > 0 || record.phoneNumberCount > 0) {
                                rowClass = "table-secondary";
                            }
                        }

                        if (sortType === "raised") {
                            if (record.percentOfGoalRaised >= 75) {
                                rowClass = "table-success";
                            } else if (record.percentOfGoalRaised >= 50) {
                                rowClass = "table-secondary";
                            } else if (record.percentOfGoalRaised > 0) {
                                rowClass = "table-warning";
                            } else {
                                rowClass = "table-danger";
                            }
                        }

                        return (
                            <tr key={record.id} className={rowClass}>
                                <td>{`${record.firstName} ${record.lastName}`}</td>
                                <td><a href={`mailto:${record.email}`}>{record.email}</a></td>
                                <td><a href={`tel:${record.phoneNumber}`}>{record.phoneNumber}</a> - <a href={`sms:${record.phoneNumber}`}>Txt</a></td>
                                <td style={{ textAlign: "right" }}>{percentFormatter.format(record.percentOfGoalRaised)}%</td>
                                <td style={{ textAlign: "right" }}>{currencyFormatter.format(record.raised)}</td>
                                <td style={{ textAlign: "right" }}>{currencyFormatter.format(record.goal)}</td>
                                <td style={{ textAlign: "right" }}>{record.smtpAddressCount}</td>
                                <td style={{ textAlign: "right" }}>{record.phoneNumberCount}</td>
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        </LoadingOverlay>
    );
}
