import React, { useState, useEffect } from "react";

export default function Unsubscribe (props) {
    useEffect(() => {
        const urlSearchParams = new URLSearchParams(window.location.search);
        const params = Object.fromEntries(urlSearchParams.entries());
        const keyNames = Object.keys(params);
        const code = keyNames[0];

        (async () => {
            const url = `/api/donations/unsubscribe/${encodeURIComponent(code)}`;
            await fetch(url,
                {
                    method: "GET",
                    headers: {
                        "Accept": "application/json",
                        "Content-Type": "application/json"
                    }
                })
                .then(response => response.text())
                .then(text => text.length ? JSON.parse(text) : {})
                .then((response) => {
                    if (response.errors) {
                        console.error(response);
                        return;
                    }
                })
                .catch(error => {
                    console.error(error);
                });
        })();
    }, []);

    return (
        <p>
            You have been unsubscribed.
        </p>
    );
}
