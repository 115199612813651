import React, { useState, useEffect } from "react";
import ProgressBar from "react-bootstrap/ProgressBar";
import LoadingOverlay from "react-loading-overlay";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuoteLeft, faQuoteRight } from "@fortawesome/free-solid-svg-icons";
import "./Fundraiser.css";

export default function Fundraiser (props) {
    const [isLoading, setIsLoading] = useState(false);
    const [model, setModel] = useState({});
    const [goalPercent, setGoalPercent] = useState(0);
    const [code, setCode] = useState(null);
    const [customAmount, setCustomAmount] = useState(20);
    const [baseUrl, setBaseUrl] = useState("");

    useEffect(() => {
        setIsLoading(true);

        const urlSearchParams = new URLSearchParams(window.location.search);
        const params = Object.fromEntries(urlSearchParams.entries());
        const keyNames = Object.keys(params);

        let code = null;
        if (keyNames.length === 1) {
            code = keyNames[0];
            setCode(keyNames[0]);
        }

        let url = "";
        if (code) {
            url = `/api/donations/${encodeURIComponent(code)}`;
            setBaseUrl(`donate?${code}&`);
        } else {
            url = "/api/donations";
            setBaseUrl(`donate?`);
        }

        (async () => {
            await fetch(url,
                {
                    method: "GET",
                    headers: {
                        "Accept": "application/json",
                        "Content-Type": "application/json"
                    }
                })
                .then(response => response.text())
                .then(text => text.length ? JSON.parse(text) : {})
                .then((response) => {
                    if (response.errors) {
                        console.error(response);
                        return;
                    }

                    if (response.fundraiserGoal) {
                        setGoalPercent((response.fundraiserRaised / response.fundraiserGoal) * 100);
                    }
                    setModel(response);

                    if (!response.campaignActive) {
                        window.location.href = "/contacts#email-addresses";
                    }
                })
                .catch(error => {
                    console.error(error);
                });

            setIsLoading(false);
        })();
    }, []);

    const onCustomAmountChange = (event) => {
        setCustomAmount(event.target.value);
    };

    const onCustomAmountClick = (event) => {
        window.location.href = code ? `/donate?${code}&a=${customAmount}` : `/donate?a=${customAmount}`;
    };
    
    const loadingOverlayStyles = {
        paddingTop: "4.5rem",
        minHeight: "75rem",
        overlay: (base) => ({
            ...base,
            background: "rgba(255, 255, 255, 0.5)"
        }),
        spinner: (base) => ({
            ...base,
            width: "60px",
            "& svg circle": { stroke: "rgba(0, 0, 0, 1.0)" }
        })
    };

    const FacebookButton = (props) => {
        return (
            <iframe title="Facebook" src="https://www.facebook.com/plugins/share_button.php?href=https%3A%2F%2Frrhs-band.com&layout&size&width=77&height=20&appId" width={77} height={20} style={{ border: "none", overflow: "hidden" }} scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>
        );
    };

    if (!model.campaignActive) {
        return (<></>);
    } else {
        return (
            <LoadingOverlay active={isLoading} spinner styles={loadingOverlayStyles}>
                {code ? <>
                    <div className="row mt-3" style={{ backgroundColor: "#002a5b", color: "#fff", boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px" }}>
                        <div className="col-md" style={{ maxWidth: "280px" }}>
                            <div style={{ backgroundColor: "#fff", width: "220px", margin: "20px", padding: "10px", transform: "rotate(-4deg)" }}>
                                <img src={model.fundraiserProfileImageUrl} style={{ width: "200px" }} alt={model.fundraiserFullName} /><br />
                                <div style={{ color: "#002a5b", height: "40px", width: "100%", textAlign: "center", verticalAlign: "middle", fontFamily: "Impact, Charcoal, sans-serif", paddingTop: "10px" }}>
                                    {model.fundraiserFullName}
                                </div>
                            </div>
                        </div>
                        <div className="col-md p-3">
                            <div style={{ width: "100%", textAlign: "center", display: model.fundraiserRemaining > 0 ? "" : "none" }}>${Number(model.fundraiserRemaining).toLocaleString()} left to goal</div>
                            <ProgressBar animated={false} now={goalPercent} label={goalPercent >= 20 ? `${goalPercent.toFixed(0)}%` : null} striped={false} style={{ height: "20px", backgroundColor: "white" }} />
                            <div className="row">
                                <div className="col-md">
                                    ${Number(model.fundraiserRaised).toLocaleString()} raised
                                </div>
                                <div className="col-md">
                                    <span className="float-right">My ${Number(model.fundraiserGoal).toLocaleString()} goal</span>
                                </div>
                            </div>
                            <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                <FontAwesomeIcon icon={faQuoteLeft} color={"#bdaa6f"} style={{ fontSize: "40px" }} />
                                <div dangerouslySetInnerHTML={{ __html: model.fundraiserContent }} style={{ fontSize: "18px", padding: "20px" }}></div>
                                <FontAwesomeIcon icon={faQuoteRight} color={"#bdaa6f"} style={{ fontSize: "40px" }} />
                            </div>
                        </div>
                    </div>
                    <br />
                </> : null}

                <div style={{ width: "100%", textAlign: "center" }}>

                    <FacebookButton />

                    <h1>Donate Now</h1>

                    <div className="donate-card">
                        <h2>$1,000 Donation</h2>
                        <p>
                            A $1,000 donation will buy 5 guard uniforms or fund a tech for the marching season.
                        </p>
                        <a href={`${baseUrl}a=1000`} className="btn btn-primary">Donate $1,000</a>
                    </div>

                    <div className="donate-card">
                        <h2>$500 Donation</h2>
                        <p>
                            A $500 donation will create one of our pipe props or buy 5 pieces of music.
                        </p>
                        <a href={`${baseUrl}a=500`} className="btn btn-primary">Donate $500</a>
                    </div>

                    <div className="donate-card">
                        <h2>$250 Donation</h2>
                        <p>
                            A $250 donation will buy silks for one movement of the show or 35 Mario shirts for wind players.
                        </p>
                        <a href={`${baseUrl}a=250`} className="btn btn-primary">Donate $250</a>
                    </div>

                    <div className="donate-card">
                        <h2>$100 Donation</h2>
                        <p>
                            A $100 donation will repair one instrument or cover a judge blind in vinyl.
                        </p>
                        <a href={`${baseUrl}a=100`} className="btn btn-primary">Donate $100</a>
                    </div>

                    <div className="donate-card">
                        <h2>$50 Donation</h2>
                        <p>
                            A $50 donation will buy us 10 Luigi hats for the percussionists!
                        </p>
                        <a href={`${baseUrl}a=50`} className="btn btn-primary">Donate $50</a>
                    </div>

                    <div className="donate-card">
                        <h2>$35 Donation</h2>
                        <p>
                            Every donation makes a difference and is greatly appreciated!
                        </p>
                        <a href={`${baseUrl}a=35`} className="btn btn-primary">Donate $35</a>
                    </div>

                    <div className="donate-card">
                        <h2>Custom Donation</h2>
                        <p>
                            Enter your custom amount below.
                        </p>
                        <p>
                            <input className="form-control" type="number" onChange={onCustomAmountChange} value={customAmount} min={20} max={2500} />
                            <small className="form-text text-muted">$20.00 minimum.</small>
                        </p>
                        <button className="btn btn-primary" onClick={onCustomAmountClick}>Donate</button>
                    </div>

                    <br />
                    <FacebookButton />
                    <br /><br />

                </div>

            </LoadingOverlay>
        );
    }
}
