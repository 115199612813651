import React, { useState, useEffect } from "react";

export default function CheerWall(props) {
    const [model, setModel] = useState([]);
    const [pageNumber, setPageNumber] = useState(1);

    const load = (loadPageNumber) => {
        (async () => {
            await fetch(`/api/donations/cheer-wall/${loadPageNumber}`,
                {
                    method: "GET",
                    headers: {
                        "Accept": "application/json",
                        "Content-Type": "application/json"
                    }
                })
                .then(response => response.text())
                .then(text => text.length ? JSON.parse(text) : {})
                .then((response) => {
                    if (response.errors) {
                        console.error(response);
                        return;
                    }

                    setModel(response);
                })
                .catch(error => {
                    console.error(error);
                });
        })();
    };

    useEffect(() => {
        load(1);
    }, []);

    const loadMore = (event) => {
        let nextPageNumber = pageNumber + 1;
        setPageNumber(nextPageNumber);
        load(nextPageNumber);
    };

    return (
        <>
            <div style={{ display: model.length > 0 ? "" : "none", width: "100%", paddingTop: "20px" }}>
                <h2 style={{ textAlign: "center" }}>Cheer Wall</h2>
                <h3 style={{ textAlign: "center" }}>Thanks to our supporters!</h3>
            </div>

            <div className="row">
                {model.map((item, index) => {
                    return (
                        <div key={index} className="col-md-4">
                            <div style={{ marginTop: "20px", padding: "10px", backgroundColor: "#F0F0F0", borderRadius: "6px" }}>
                                <h4 style={{ color: "#0d223f" }}>{item.patronFullName ? item.patronFullName : "Anonymous"} donated ${Number(item.amount).toLocaleString()}</h4>
                                <span style={{ color: "#4c4c4c" }}>Supporting {item.fundraiserFullName ? item.fundraiserFullName : "the group"} - {item.pastTimeString}</span>
                                <div style={{ paddingTop: "10px" }}>
                                    <strong>
                                        {item.message}
                                    </strong>
                                </div>
                            </div>
                        </div>
                    );
                })}
            </div>

            <div style={{ display: model.length >= 12 ? "" : "none", width: "100%", paddingTop: "20px", textAlign: "center" }}>
                <button className="btn btn-success" onClick={loadMore}>Show More</button>
            </div>
        </>
    );
}
