import React, { useState, useEffect } from "react";
import authService from '../api-authorization/AuthorizeService';
import { AuthenticationResultStatus } from "../api-authorization/AuthorizeService";

export default function SendFundraiserCallToAction (props) {
    const [subscription, setSubscription] = useState(null);
    const [identity, setIdentity] = useState({ isAuthenticated: false, userName: null });
    const [smtpClicked, setSmtpClicked] = useState(false);
    const [smsClicked, setSmsClicked] = useState(false);
    const [smtpContent, setSmtpContent] = useState("");
    const [smsContent, setSmsContent] = useState("");
    const [smtpButtonText, setSmtpButtonText] = useState("Send Contact Goal Reminder E-Mail Message to All Fundraisers");
    const [smsButtonText, setSmsButtonText] = useState("Send Contact Goal Reminder Text Message to All Fundraisers");

    useEffect(() => {
        setSubscription(authService.subscribe(() => populateState()));
        populateState();

        //return () => {
        //    authService.unsubscribe(subscription);
        //};
    }, []);

    const populateState = () => {
        (async () => {
            const [isAuthenticated, user] = await Promise.all([authService.isAuthenticated(), authService.getUser()])

            if (user.role !== "Administrator") {
                (async () => {
                    const state = { returnUrl: "/Identity/Account/Login" };
                    const result = await authService.signOut(state);
                    if (result.status === AuthenticationResultStatus.Success) {
                        window.location.replace("/Identity/Account/Login");
                    }
                    return;
                })();
            }

            setIdentity({
                isAuthenticated,
                userName: user && user.name,
                firstName: user && user.given_name,
                lastName: user && user.family_name,
                email: user && user.email,
                phone: user && user.phone_number,
                role: user && user.role,
            });
        })();
    };

    const sendSmtp = (event) => {
        (async () => {
            const token = await authService.getAccessToken();

            const model = { templateName: "Dynamic Fundraiser Call to Action", content: smtpContent };

            await fetch("/api/smtp/call-to-action",
                {
                    method: "POST",
                    headers: {
                        "Accept": "application/json",
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${token}`
                    },
                    body: JSON.stringify(model)
                })
                .then(response => response.text())
                .then(text => text.length ? JSON.parse(text) : {})
                .then((response) => {
                    if (response.errors) {
                        console.error(response);
                        return;
                    }

                    setSmtpButtonText("Send Request Submitted");
                    setSmtpClicked(true);

                    console.log(response);
                })
                .catch(error => {
                    console.error(error);
                });
        })();
    };

    const sendSms = (event) => {
        (async () => {
            const token = await authService.getAccessToken();

            const model = { templateName: "Dynamic Fundraiser Call to Action", content: smsContent };

            await fetch("/api/sms/call-to-action",
                {
                    method: "POST",
                    headers: {
                        "Accept": "application/json",
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${token}`
                    },
                    body: JSON.stringify(model)
                })
                .then(response => response.text())
                .then(text => text.length ? JSON.parse(text) : {})
                .then((response) => {
                    if (response.errors) {
                        console.error(response);
                        return;
                    }

                    setSmsButtonText("Send Request Submitted");
                    setSmsClicked(true);

                    console.log(response);
                })
                .catch(error => {
                    console.error(error);
                });
        })();
    };

    const updateSmtpContent = (event) => {
        setSmtpContent(event.target.value);
    };

    const updateSmsContent = (event) => {
        setSmsContent(event.target.value);
    };

    return (
        <>
            <div className="form-group">
                <a href="/admin" className="btn btn-primary">&lt; Back to Admin</a>
            </div>

            <hr />

            <div className="form-group">
                <label>E-Mail Message (SMTP) Content</label>
                <textarea className="form-control" onChange={e => updateSmtpContent(e)} style={{ height: "100px" }} value={smtpContent} maxLength={2000}></textarea>
                <small className="form-text text-muted">Enter up to 2,000 characters including spaces.</small>
            </div>

            <p>
                <button className={`btn btn-${smtpClicked ? "danger" : "primary"}`} onClick={sendSmtp} disabled={smtpClicked || !smtpContent}>{smtpButtonText}</button>
            </p>

            <hr />

            <div className="form-group">
                <label>Text Message (SMS) Content</label>
                <textarea className="form-control" onChange={e => updateSmsContent(e)} style={{ height: "100px" }} value={smsContent} maxLength={100}></textarea>
                <small className="form-text text-muted">Enter up to 100 characters including spaces.</small>
            </div>

            <p>
                <button className={`btn btn-${smsClicked ? "danger" : "primary"}`} onClick={sendSms} disabled={smsClicked || !smsContent}>{smsButtonText}</button>
            </p>
        </>
    );
}
