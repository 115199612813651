import React, { Component } from 'react';
import { Route } from 'react-router';
import { Layout } from './components/Layout';
import Campaign from './donor/Campaign';
import Contacts from "./fundraiser/Contacts";
import AuthorizeRoute from './api-authorization/AuthorizeRoute';
import ApiAuthorizationRoutes from './api-authorization/ApiAuthorizationRoutes';
import { ApplicationPaths } from './api-authorization/ApiAuthorizationConstants';
import Fundraiser from './donor/Fundraiser';
import Donate from './donor/Donate';
import Admin from './admin/Admin';
import ParticipationReport from './admin/ParticipationReport';
import SendFundraiserCallToAction from './admin/SendFundraiserCallToAction';
import SendDonorCallToAction from "./admin/SendDonorCallToAction";
import ContactPage from './fundraiser/ContactPage';
import DonationConfirmation from "./donor/DonationConfirmation";
import Unsubscribe from './donor/Unsubscribe';
import PrivacyPolicy from './pages/PrivacyPolicy';
import TermsOfUse from './pages/TermsOfUse';

export default class App extends Component {
  static displayName = App.name;

    render () {
        return (
            <Layout>
                <Route exact path='/' component={Campaign} />
                <Route path='/fundraiser' component={Fundraiser} />
                <Route path='/donate' component={Donate} />
                <Route path='/donation-confirmation' component={DonationConfirmation} />
                <Route path='/unsubscribe' component={Unsubscribe} />
                <Route path='/privacy-policy' component={PrivacyPolicy} />
                <Route path='/terms-of-use' component={TermsOfUse} />
                <AuthorizeRoute path='/contact-page' component={ContactPage} />
                <AuthorizeRoute path='/contacts' component={Contacts} />
                <AuthorizeRoute path='/admin' component={Admin} />
                <AuthorizeRoute path='/participation-report' component={ParticipationReport} />
                <AuthorizeRoute path='/send-fundraiser-call-to-action' component={SendFundraiserCallToAction} />
                <AuthorizeRoute path='/send-donor-call-to-action' component={SendDonorCallToAction} />
                <Route path={ApplicationPaths.ApiAuthorizationPrefix} component={ApiAuthorizationRoutes} />
            </Layout>
        );
    }
}
