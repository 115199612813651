import React from "react";
import { ToastContainer, toast, Zoom } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import * as enums from "../core/enums";

export default function Message(props) {
    if (!props.message) {
        return null;
    }

    const toastProps = {
        position: "top-right",
        autoClose: props.duration || 5000,
        hideProgressBar: props.hideProgressBar || false,
        closeOnClick: props.closeOnClick || true,
        pauseOnHover: props.pauseOnHover || true,
        draggable: props.draggable || true,
        progress: undefined,
        transition: Zoom
    };

    const isToast = props.types.includes(enums.MessageType.TOAST);
    const isAlert = props.types.includes(enums.MessageType.ALERT);
    if (props.types.includes(enums.MessageType.CONSOLE)) {
        console.log(props.message);
    }

    let alertClass = "";

    switch (props.severity) {
        case enums.MessageSeverity.INFO:
            alertClass = "primary";
            toastProps.type = toast.TYPE.INFO;
            break;
        case enums.MessageSeverity.SUCCESS:
            alertClass = "success";
            toastProps.type = toast.TYPE.SUCCESS;
            break;
        case enums.MessageSeverity.WARNING:
            alertClass = "warning";
            toastProps.type = toast.TYPE.WARNING;
            break;
        case enums.MessageSeverity.ERROR:
            alertClass = "danger";
            toastProps.type = toast.TYPE.ERROR;
            break;
        default:
            alertClass = "primary";
            toastProps.type = toast.TYPE.INFO;
            break;
    }

    if (isToast) {
        toast(props.message, toastProps);
    }

    return (
        <>
            {isToast ?
                <ToastContainer
                    position="top-right"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                />
                : null}

            {isAlert ?
                <div className={`alert alert-${alertClass}`}>
                    {props.message}
                </div>
                : null}
        </>
    );
}
