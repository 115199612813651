import React, { useState, useEffect } from "react";
import authService from '../api-authorization/AuthorizeService';
import LoadingOverlay from "react-loading-overlay";
import { NavHashLink } from "react-router-hash-link";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";

library.add(faTrashAlt);

export default function Contacts (props) {
    const minSmtpAddresses = 20;
    const minExcelsSmtpAddresses = 40;
    const minPhoneNumbers = 10;
    const minExcelsPhoneNumbers = 20;

    const [isLoading, setIsLoading] = useState(false);
    const [subscription, setSubscription] = useState(null);
    const [identity, setIdentity] = useState({ isAuthenticated: false, userName: null });
    const [smtpAddressList, setSmtpAddressList] = useState("");
    const [smtpAddresses, setSmtpAddresses] = useState([]);
    const [smtpAddressMessages, setSmtpAddressMessages] = useState(null);
    const [smtpAddressAlertText, setSmtpAddressAlertText] = useState("");
    const [smtpAddressAlertType, setSmtpAddressAlertType] = useState("");
    const [phoneNumberList, setPhoneNumberList] = useState("");
    const [phoneNumbers, setPhoneNumbers] = useState([]);
    const [phoneNumberMessages, setPhoneNumberMessages] = useState(null);
    const [phoneNumberAlertText, setPhoneNumberAlertText] = useState("");
    const [phoneNumberAlertType, setPhoneNumberAlertType] = useState("");
    const [minimumsSatisfied, setMinimumsSatisfied] = useState(false);
    const [minimumSmtpAddressesSatisfied, setMinimumSmtpAddressesSatisfied] = useState(false);
    const [minimumPhoneNumbersSatisfied, setMinimumPhoneNumbersSatisfied] = useState(false);

    useEffect(() => {
        setSubscription(authService.subscribe(() => populateState()));
        populateState();

        setIsLoading(true);
        (async () => {
            const token = await authService.getAccessToken();

            await fetch("/api/contacts",
                {
                    method: "GET",
                    headers: {
                        "Accept": "application/json",
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${token}`
                    }
                })
                .then(response => response.text())
                .then(text => text.length ? JSON.parse(text) : {})
                .then((response) => {
                    if (response.errors) {
                        console.error(response);
                        return;
                    }

                    const emails = response.filter(contact => contact.email);
                    setSmtpAddresses(emails);
                    updateSmtpAddressAlertText(emails);
                    updateSmtpAddressAlertText(emails);
                    if (emails.length >= minSmtpAddresses) {
                        setMinimumSmtpAddressesSatisfied(true);
                    }

                    const phones = response.filter(contact => contact.phone);
                    setPhoneNumbers(phones);
                    updatePhoneNumberAlertText(phones);
                    if (phones.length >= minPhoneNumbers) {
                        setMinimumPhoneNumbersSatisfied(true);
                        setMinimumsSatisfied(true);
                    }
                })
                .catch(error => {
                    console.error(error);
                });

            setIsLoading(false);
        })();

        //return () => {
        //    authService.unsubscribe(subscription);
        //};
    }, []);

    const populateState = () => {
        (async () => {
            const [isAuthenticated, user] = await Promise.all([authService.isAuthenticated(), authService.getUser()])
            setIdentity({
                isAuthenticated,
                userName: user && user.name,
                firstName: user && user.given_name,
                lastName: user && user.family_name,
                email: user && user.email,
                phone: user && user.phone_number,
                role: user && user.role,
            });
        })();
    };

    const updateSmtpAddressList = (event) => {
        setSmtpAddressList(event.target.value);
    };

    const updatePhoneNumberList = (event) => {
        setPhoneNumberList(event.target.value);
    };

    const onUpdateSmtpAddressesClick = (event) => {
        if (!smtpAddressList) {
            return;
        }

        setIsLoading(true);

        const model = { emailList: smtpAddressList };
        (async () => {
            const token = await authService.getAccessToken();
            await fetch("/api/contacts",
                {
                    method: "PUT",
                    headers: {
                        "Accept": "application/json",
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${token}`
                    },
                    body: JSON.stringify(model)
                })
                .then(response => response.text())
                .then(text => text.length ? JSON.parse(text) : {})
                .then((response) => {
                    if (response.errors) {
                        console.error(response);
                        return;
                    }

                    setSmtpAddresses(response.emailContacts);

                    if (response.emailContacts.length) {
                        setSmtpAddressMessages(response.messages.join("</br>"));
                    } else {
                        setSmtpAddressMessages(null);
                    }

                    updateSmtpAddressAlertText(response.emailContacts);
                    setSmtpAddressList("");

                    setIsLoading(false);

                    if (response.emailContacts.length >= minSmtpAddresses) {
                        setMinimumSmtpAddressesSatisfied(true);
                    }
                })
                .catch(error => {
                    console.error(error);
                });
        })();
    };

    const onUpdatePhoneNumbersClick = (event) => {
        if (!phoneNumberList) {
            return;
        }

        setIsLoading(true);

        const model = { phoneList: phoneNumberList };
        (async () => {
            const token = await authService.getAccessToken();
            await fetch("/api/contacts",
                {
                    method: "PUT",
                    headers: {
                        "Accept": "application/json",
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${token}`
                    },
                    body: JSON.stringify(model)
                })
                .then(response => response.text())
                .then(text => text.length ? JSON.parse(text) : {})
                .then((response) => {
                    if (response.errors) {
                        console.error(response);
                        return;
                    }

                    setPhoneNumbers(response.phoneContacts);

                    if (response.messages.length) {
                        setPhoneNumberMessages(response.messages.join("</br>"));
                    } else {
                        setPhoneNumberMessages(null);
                    }

                    updatePhoneNumberAlertText(response.phoneContacts);
                    setPhoneNumberList("");

                    setIsLoading(false);

                    if (response.phoneContacts.length >= minPhoneNumbers) {
                        setMinimumPhoneNumbersSatisfied(true);
                        setMinimumsSatisfied(true);
                    }
                })
                .catch(error => {
                    console.error(error);
                });
        })();
    };

    const deleteSmtp = (id) => {
        setIsLoading(true);

        (async () => {
            const token = await authService.getAccessToken();
            await fetch(`/api/contacts/${id}`,
                {
                    method: "DELETE",
                    headers: {
                        "Accept": "application/json",
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${token}`
                    }
                })
                .then(response => response.text())
                .then(text => text.length ? JSON.parse(text) : {})
                .then((response) => {
                    if (response.errors) {
                        console.error(response);
                        return;
                    }

                    let addresses = smtpAddresses.filter(x => x.contactId !== id)
                    setSmtpAddresses(addresses);

                    updateSmtpAddressAlertText(addresses);
                    setSmtpAddressList("");

                    setIsLoading(false);

                    if (addresses.length >= minSmtpAddresses) {
                        setMinimumSmtpAddressesSatisfied(true);
                    }
                })
                .catch(error => {
                    console.error(error);
                });
        })();
    };

    const deleteSms = (id) => {
        setIsLoading(true);

        (async () => {
            const token = await authService.getAccessToken();
            await fetch(`/api/contacts/${id}`,
                {
                    method: "DELETE",
                    headers: {
                        "Accept": "application/json",
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${token}`
                    }
                })
                .then(response => response.text())
                .then(text => text.length ? JSON.parse(text) : {})
                .then((response) => {
                    if (response.errors) {
                        console.error(response);
                        return;
                    }

                    let numbers = phoneNumbers.filter(x => x.contactId !== id)
                    setPhoneNumbers(numbers);

                    updatePhoneNumberAlertText(numbers);
                    setPhoneNumberList("");

                    setIsLoading(false);

                    if (numbers.length >= minPhoneNumbers) {
                        setMinimumPhoneNumbersSatisfied(true);
                    }
                })
                .catch(error => {
                    console.error(error);
                });
        })();
    };

    const updatePhoneNumberAlertText = (list) => {
        const minCount = minPhoneNumbers;
        const minExcelsCount = minExcelsPhoneNumbers;
        const phoneNumberCount = list.length;
        let type = "";
        let message = "";
        if (phoneNumberCount === 0) {
            type = "danger";
            message = `No phone numbers added yet.  You need a minimum of ${minCount}.  You got this!`;
        } else if (phoneNumberCount < minCount) {
            type = "danger";
            message = `${phoneNumberCount} of ${minCount} phone numbers.  Keep it up!  You need ${minCount - phoneNumberCount} more phone numbers to reach the minimum!`;
        } else if (phoneNumberCount === minCount) {
            type = "primary";
            message = `You have ${minCount} phone numbers.  That's pretty good!  See if you can add more than the minimum!`;
        } else if (phoneNumberCount >= minCount && phoneNumberCount < minExcelsCount) {
            type = "success";
            message = `${phoneNumberCount} phone numbers!!!  Totally awesome!`;
        } else if (phoneNumberCount >= minExcelsCount) {
            type = "success";
            message = `${phoneNumberCount} phone numbers!!!  Killin' it!!!`;
        }
        setPhoneNumberAlertType(type)
        setPhoneNumberAlertText(message);
    };

    const updateSmtpAddressAlertText = (list) => {
        const minCount = minSmtpAddresses;
        const minExcelsCount = minExcelsSmtpAddresses
        const addressCount = list.length;
        let type = "";
        let message = "";
        if (addressCount === 0) {
            type = "danger";
            message = `No e-mail addresses added yet.  You need a minimum of ${minCount}.  We know you can do it!`;
        } else if (addressCount < minCount) {
            type = "danger";
            message = `${addressCount} of ${minCount} e-mail addresses.  Keep pluggin'!  You need ${minCount - addressCount} more addresses to reach the minimum!`;
        } else if (addressCount === minCount) {
            type = "primary";
            message = `You have ${minCount} e-mail addresses.  Good job!  See if you can add more than the minimum!`;
        } else if (addressCount >= minCount && addressCount < minExcelsCount) {
            type = "success";
            message = `${addressCount} e-mail addresses!!!  You totally rock!`;
        } else if (addressCount >= minExcelsCount) {
            type = "success";
            message = `${addressCount} e-mail addresses!!!  YOU ARE A ROCK STAR!!!`;
        }
        setSmtpAddressAlertType(type)
        setSmtpAddressAlertText(message);
    };

    const loadingOverlayStyles = {
        overlay: (base) => ({
            ...base,
            background: "rgba(255, 255, 255, 0.5)"
        }),
        spinner: (base) => ({
            ...base,
            width: "60px",
            "& svg circle": { stroke: "rgba(0, 0, 0, 1.0)" }
        })
    }

    return (
        <LoadingOverlay active={isLoading} spinner styles={loadingOverlayStyles}>
            <div className="container">
                <section id="email-addresses" style={{ paddingTop: "4.5rem", minHeight: "75rem" }}>
                    <h2>So, how's {identity.firstName} doing?</h2>
                    <div className={`alert alert-${smtpAddressAlertType}`}>{smtpAddressAlertText}</div>
                    <div className="alert alert-danger" role="alert" style={{ display: smtpAddressMessages ? "" : "none" }} dangerouslySetInnerHTML={{ __html: smtpAddressMessages }}></div>

                    <h2>Your Contact E-Mail Addresses</h2>

                    <div className="form-group">
                        <label>Add E-Mail Addresses</label>
                        <textarea name="emailAddressTextArea" className="form-control" onChange={e => updateSmtpAddressList(e)} style={{ height: "100px" }} placeholder="jane.smith@thingsforsale.com, john.smith@widgetsrus.com, ..." value={smtpAddressList}></textarea>
                        <small className="form-text text-muted">Enter or paste in multiple e-mail addresses separated by commas, semi-colons, spaces, tabs, or carriage returns above.</small>
                    </div>

                    <div className="form-group">
                        <button className="btn btn-primary mr-3" onClick={onUpdateSmtpAddressesClick}>Add E-Mail Addresses</button>
                        <NavHashLink smooth to="/contacts#phone-numbers" className="btn btn-success" style={{ display: !minimumSmtpAddressesSatisfied ? "none" : "" }}>I'm Done With E-Mail Addresses</NavHashLink>
                    </div>

                    <div className="row pl-3">
                        {smtpAddresses.length > 0 ? smtpAddresses.map(smtpAddress => {
                            return <div key={smtpAddress.contactId} className="col-md-4 p-2" style={{ backgroundColor: "lightgray", border: "2px solid white" }}>{smtpAddress.email} <FontAwesomeIcon icon={faTrashAlt} className="float-right" onClick={() => deleteSmtp(smtpAddress.contactId)} style={{ height: "100%", cursor: "pointer" }} /></div>;
                        }) : <div className="p-2" style={{ backgroundColor: "lightgray", border: "2px solid white" }}><strong>No e-mail addresses added yet.</strong></div>}
                    </div>
                </section>

                <section id="phone-numbers" style={{ paddingTop: "4.5rem", minHeight: "75rem" }}>
                    <h2>So, how's {identity.firstName} doing?</h2>
                    <div className={`alert alert-${smtpAddressAlertType}`}>{smtpAddressAlertText}</div>
                    <div className={`alert alert-${phoneNumberAlertType}`}>{phoneNumberAlertText}</div>
                    <div className="alert alert-danger" role="alert" style={{ display: phoneNumberMessages ? "" : "none" }} dangerouslySetInnerHTML={{ __html: phoneNumberMessages }}></div>

                    <h2>Your Contact Phone Numbers</h2>

                    <div className="form-group">
                        <label>Add Mobile Phone Numbers</label>
                        <textarea name="phoneAddressTextArea" className="form-control" onChange={e => updatePhoneNumberList(e)} style={{ height: "100px" }} placeholder="404-123-3456, (404) 765-4321, ..." value={phoneNumberList}></textarea>
                        <small className="form-text text-muted">Enter or paste in multiple phone numbers separated by commas, semi-colons, tabs, or carriage returns above.</small>
                    </div>

                    <div className="form-group">
                        <button className="btn btn-primary mr-3" onClick={onUpdatePhoneNumbersClick}>Add Phone Numbers</button>
                        <NavHashLink smooth to="/contacts#good-to-go" className="btn btn-success" style={{ display: !minimumPhoneNumbersSatisfied ? "none" : "" }}>I'm Done With Phone Numbers</NavHashLink>
                    </div>

                    <div className="row pl-3">
                        {phoneNumbers.length > 0 ? phoneNumbers.map(phoneNumber => {
                            return <div key={phoneNumber.contactId} className="col-md-4 p-2" style={{ backgroundColor: "lightgray", border: "2px solid white" }}>{phoneNumber.phone} <FontAwesomeIcon icon={faTrashAlt} className="float-right" onClick={() => deleteSms(phoneNumber.contactId)} style={{ height: "100%", cursor: "pointer" }} /></div>;
                        }) : <div className="p-2" style={{ backgroundColor: "lightgray", border: "2px solid white" }}><strong>No phone numbers added yet.</strong></div>}
                    </div>
                </section>

                <section id="good-to-go" style={{ display: !minimumsSatisfied ? "none" : "", paddingTop: "4.5rem", minHeight: "75rem" }}>
                    <h1>Nice job, {identity.firstName}!</h1>

                    <h3 style={{ color: "red" }}>
                        You added:<br />
                        <ul>
                            <li>{smtpAddresses.length} e-mail addresses and</li>
                            <li>{phoneNumbers.length} mobile phone numbers.</li>
                        </ul>
                    </h3>

                    <img className="img-fluid" alt="" src="/images/the-office.webp" style={{ width: "100%", border: "4px solid white", borderRadius: "20px" }} />
                </section>
            </div>
        </LoadingOverlay>
    );
}
