import React, { useState, useEffect } from "react";
import LoadingOverlay from "react-loading-overlay";
import ProgressBar from "react-bootstrap/ProgressBar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClock, faUser } from "@fortawesome/free-solid-svg-icons";
import CheerWall from "../components/CheerWall";
import "./Campaign.css";

export default function Campaign(props) {
    const [isLoading, setIsLoading] = useState(false);
    const [model, setModel] = useState({});
    const [code, setCode] = useState(null);
    const [goalPercent, setGoalPercent] = useState(0);

    useEffect(() => {
        setIsLoading(true);

        const urlSearchParams = new URLSearchParams(window.location.search);
        const params = Object.fromEntries(urlSearchParams.entries());
        const keyNames = Object.keys(params);
        let code = keyNames[0];
        if (keyNames[0] === "fbclid") {
            code = null;
        }
        setCode(code);

        (async () => {
            let url = "/api/donations/";
            if (code) {
                url = `/api/donations/${encodeURIComponent(code)}`;
            }
            await fetch(url,
                {
                    method: "GET",
                    headers: {
                        "Accept": "application/json",
                        "Content-Type": "application/json"
                    }
                })
                .then(response => response.text())
                .then(text => text.length ? JSON.parse(text) : {})
                .then((response) => {
                    if (response.errors) {
                        console.error(response);
                        setCode(null);
                        return;
                    }

                    setGoalPercent((response.campaignRaised / response.campaignGoal) * 100);
                    setModel(response);

                    if (!response.campaignActive) {
                        window.location.href = "/contacts#email-addresses";
                    }
                })
                .catch(error => {
                    console.error(error);
                });

            setIsLoading(false);
        })();
    }, []);

    const loadingOverlayStyles = {
        overlay: (base) => ({
            ...base,
            background: "rgba(255, 255, 255, 0.5)"
        }),
        spinner: (base) => ({
            ...base,
            width: "60px",
            "& svg circle": { stroke: "rgba(0, 0, 0, 1.0)" }
        })
    };

    const DonateButton = (props) => {
        return (
            <a href={code ? `/fundraiser?${encodeURIComponent(code)}` : "/fundraiser"} className={`btn btn-${props.btnClass} btn-xl`} >
                Donate Now {model.contactId ? `for ${model.fundraiserFullName}` : ""}
            </a>
        );
    };

    const FacebookButton = (props) => {
        return (
            <iframe title="Facebook" src="https://www.facebook.com/plugins/share_button.php?href=https%3A%2F%2Frrhs-band.com&layout&size&width=77&height=20&appId" width={77} height={20} style={{ border: "none", overflow: "hidden" }} scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>
        );
    };

    if (!model.campaignActive) {
        return (<></>);
    } else {
        return (
            <LoadingOverlay active={isLoading} spinner styles={loadingOverlayStyles}>
                <div className="row heading-container">
                    <div className="col-md-3">
                        <img className="heading-image" src="/images/rrhs.webp" alt="" />
                    </div>
                    <div className="col-md-6">
                        <span className="heading-title">{model.campaignName}</span><br />
                        <span className="heading-currency-symbol">$</span><span className="heading-raised">{Number(model.campaignRaised).toLocaleString()}</span><br />
                        raised of our ${Number(model.campaignGoal).toLocaleString()} group goal<br /><br />
                    </div>
                    <div className="col-md-3 heading-button">
                        <DonateButton btnClass="secondary" /><br /><br />
                        <FacebookButton />
                    </div>
                </div>
                <br />

                <h2>About Our Fundraiser</h2>

                <div className="row body-container">
                    <div className="col-md-8">
                        <div className="embed-responsive embed-responsive-16by9">
                            <video className="video-fluid z-depth-1" autoPlay={true} muted controls>
                                <source src="/Media/videos/march-a-thon.mp4" type="video/mp4" />
                            </video>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="body-campaign">
                            <ProgressBar animated={false} now={goalPercent} label={goalPercent >= 20 ? `${goalPercent.toFixed(0)}%` : null} striped={false} style={{ height: "20px", backgroundColor: "white" }} />
                            <div className="row pt-2">
                                <div className="col-md">
                                    ${Number(model.campaignRaised).toLocaleString()} raised
                                </div>
                                <div className="col-md">
                                    <span className="float-right">${Number(model.campaignGoal).toLocaleString()} group goal</span>
                                </div>
                            </div>
                            <div className="pt-4">
                                <FontAwesomeIcon icon={faClock} color={"#0275d8"} /> Ends on {new Date(model.campaignEndUtc).toLocaleString("en-US", { day: "numeric", year: "numeric", month: "long" })}<br />
                            </div>
                            <div className="pt-2">
                                <FontAwesomeIcon icon={faUser} color={"#0275d8"} /> {model.fundraiserCount} participants
                            </div>
                        </div>

                        <h4>Why are donations necessary?</h4>

                        <p>
                            Band and Colorguard positively impact the lives of all of our students.  These kids live for every opportunity to perform.
                            River Ridge Band and Guard have been so successful over the years because of people who care... like you!
                        </p>

                        <div className="row">
                            <div className="col-md">
                                <DonateButton btnClass="success" />
                            </div>
                            <div className="col-md pt-1">
                                <FacebookButton />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row pt-4">
                    <div className="col-md-8">
                        <div className="row">
                            <div className="col-md-3 text-center">
                                <img src="/images/rrhs.webp" alt="" style={{ margin: "0 auto", maxWidth: "100%", maxHeight: "100%" }}/><br />
                                <strong>Josh Tyree</strong><br />
                                Group Leader
                            </div>
                            <div className="col-md-9">
                                <div className="box sb2">
                                    Family, friends, and supporters - Thank you so much for taking time to view our fundraiser page!
                                    Just clicking on this link or opening this email means the world to us.  Our kids LOVE the band program,
                                    and I am blessed to be allowed to teach here.  Your support will help us offer a higher quality experience
                                    for all of our members.  If you can give, please give.  If you can't, we understand.  Regardless,
                                    please spread the word about our fundraiser by sharing it with your friends via e-mail, Facebook, and Twitter.
                                    We greatly appreciate your support!
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <h4>How are my donations used?</h4>
                        <p>
                            Our mission is: "Developing Lifelong Musicianship."  Your support will allow us to continue to do just that!
                            Donations will go towards props and uniforms for marching band, equipment and staffing for colorguard,
                            instruments, and music.  These funds will also help us maintain our current booster-funded staff members.
                        </p>
                    </div>
                </div>

                <CheerWall />

                <br />
            </LoadingOverlay>
        );
    }
}
