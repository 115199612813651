import React from "react";

export default function TermsOfUse (props) {
    return (
        <div className="container">
            <h1>
                Terms and Conditions
            </h1>
            <p>
                Effective as of July 20, 2023
            </p>
            <h2>
                Agreement between User and RRHS-Band.com
            </h2>
            <p>
                Welcome to RRHS-Band.com. The RRHS-Band.com website (the "Site") is comprised of various web pages operated by River Ridge Band Boosters, Inc. ("River Ridge Band Boosters"). RRHS-Band.com is offered to you conditioned on your acceptance without modification of the terms, conditions, and notices contained herein (the "Terms"). Your use of RRHS-Band.com constitutes your agreement to all such Terms. Please read these terms carefully, and keep a copy of them for your reference.
            </p>
            <p>
                RRHS-Band.com is a Non-Profit Site.
            </p>
            <p>
                This website is used to collect donations towards our non-profit program.
            </p>
            <h2>
                Electronic Communications
            </h2>
            <p>
                Visiting RRHS-Band.com or sending emails to River Ridge Band Boosters constitutes electronic communications. You consent to receive electronic communications and you agree that all agreements, notices, disclosures and other communications that we provide to you electronically, via email and on the Site, satisfy any legal requirement that such communications be in writing.
            </p>
            <h2>
                Children Under Thirteen
            </h2>
            <p>
                River Ridge Band Boosters does not knowingly collect, either online or offline, personal information from persons under the age of thirteen. If you are under 18, you may use RRHS-Band.com only with permission of a parent or guardian.
            </p>
            <h2>
                Links to Third Party Sites/Third Party Services
            </h2>
            <p>
                RRHS-Band.com may contain links to other websites ("Linked Sites"). The Linked Sites are not under the control of River Ridge Band Boosters and River Ridge Band Boosters is not responsible for the contents of any Linked Site, including without limitation any link contained in a Linked Site, or any changes or updates to a Linked Site. River Ridge Band Boosters is providing these links to you only as a convenience, and the inclusion of any link does not imply endorsement by River Ridge Band Boosters of the site or any association with its operators.
            </p>
            <p>
                Certain services made available via RRHS-Band.com are delivered by third party sites and organizations. By using any product, service or functionality originating from the RRHS-Band.com domain, you hereby acknowledge and consent that River Ridge Band Boosters may share such information and data with any third party with whom River Ridge Band Boosters has a contractual relationship to provide the requested product, service or functionality on behalf of RRHS-Band.com users and customers.
            </p>
            <h2>
                No Unlawful or Prohibited Use/Intellectual Property
            </h2>
            <p>
                You are granted a non-exclusive, non-transferable, revocable license to access and use RRHS-Band.com strictly in accordance with these terms of use. As a condition of your use of the Site, you warrant to River Ridge Band Boosters that you will not use the Site for any purpose that is unlawful or prohibited by these Terms. You may not use the Site in any manner which could damage, disable, overburden, or impair the Site or interfere with any other party's use and enjoyment of the Site. You may not obtain or attempt to obtain any materials or information through any means not intentionally made available or provided for through the Site.
            </p>
            <p>
                All content included as part of the Service, such as text, graphics, logos, images, as well as the compilation thereof, and any software used on the Site, is the property of River Ridge Band Boosters or its suppliers and protected by copyright and other laws that protect intellectual property and proprietary rights. You agree to observe and abide by all copyright and other proprietary notices, legends or other restrictions contained in any such content and will not make any changes thereto.
            </p>
            <p>
                You will not modify, publish, transmit, reverse engineer, participate in the transfer or sale, create derivative works, or in any way exploit any of the content, in whole or in part, found on the Site. River Ridge Band Boosters content is not for resale. Your use of the Site does not entitle you to make any unauthorized use of any protected content, and in particular you will not delete or alter any proprietary rights or attribution notices in any content. You will use protected content solely for your personal use, and will make no other use of the content without the express written permission of River Ridge Band Boosters and the copyright owner. You agree that you do not acquire any ownership rights in any protected content. We do not grant you any licenses, express or implied, to the intellectual property of River Ridge Band Boosters or our licensors except as expressly authorized by these Terms.
            </p>
            <h2>
                Use of Communication Services
            </h2>
            <p>
                The Site may contain bulletin board services, chat areas, news groups, forums, communities, personal web pages, calendars, and/or other message or communication facilities designed to enable you to communicate with the public at large or with a group (collectively, "Communication Services"). You agree to use the Communication Services only to post, send and receive messages and material that are proper and related to the particular Communication Service.
            </p>
            <p>
                By way of example, and not as a limitation, you agree that when using a Communication Service, you will not: defame, abuse, harass, stalk, threaten or otherwise violate the legal rights (such as rights of privacy and publicity) of others; publish, post, upload, distribute or disseminate any inappropriate, profane, defamatory, infringing, obscene, indecent or unlawful topic, name, material or information; upload files that contain software or other material protected by intellectual property laws (or by rights of privacy of publicity) unless you own or control the rights thereto or have received all necessary consents; upload files that contain viruses, corrupted files, or any other similar software or programs that may damage the operation of another's computer; advertise or offer to sell or buy any goods or services for any business purpose, unless such Communication Service specifically allows such messages; conduct or forward surveys, contests, pyramid schemes or chain letters; download any file posted by another user of a Communication Service that you know, or reasonably should know, cannot be legally distributed in such manner; falsify or delete any author attributions, legal or other proper notices or proprietary designations or labels of the origin or source of software or other material contained in a file that is uploaded; restrict or inhibit any other user from using and enjoying the Communication Services; violate any code of conduct or other guidelines which may be applicable for any particular Communication Service; harvest or otherwise collect information about others, including e-mail addresses, without their consent; violate any applicable laws or regulations.
            </p>
            <p>
                River Ridge Band Boosters has no obligation to monitor the Communication Services. However, River Ridge Band Boosters reserves the right to review materials posted to a Communication Service and to remove any materials in its sole discretion. River Ridge Band Boosters reserves the right to terminate your access to any or all of the Communication Services at any time without notice for any reason whatsoever.
            </p>
            <p>
                River Ridge Band Boosters reserves the right at all times to disclose any information as necessary to satisfy any applicable law, regulation, legal process or governmental request, or to edit, refuse to post or to remove any information or materials, in whole or in part, in River Ridge Band Boosters's sole discretion.
            </p>
            <p>
                Always use caution when giving out any personally identifying information about yourself or your children in any Communication Service. River Ridge Band Boosters does not control or endorse the content, messages or information found in any Communication Service and, therefore, River Ridge Band Boosters specifically disclaims any liability with regard to the Communication Services and any actions resulting from your participation in any Communication Service. Managers and hosts are not authorized River Ridge Band Boosters spokespersons, and their views do not necessarily reflect those of River Ridge Band Boosters.
            </p>
            <p>
                Materials uploaded to a Communication Service may be subject to posted limitations on usage, reproduction and/or dissemination. You are responsible for adhering to such limitations if you upload the materials.
            </p>
            <h2>
                Materials Provided to RRHS-Band.com or Posted on Any River Ridge Band Boosters Web Page
            </h2>
            <p>
                River Ridge Band Boosters does not claim ownership of the materials you provide to RRHS-Band.com (including feedback and suggestions) or post, upload, input or submit to any River Ridge Band Boosters Site or our associated services (collectively "Submissions"). However, by posting, uploading, inputting, providing or submitting your Submission you are granting River Ridge Band Boosters, our affiliated companies and necessary sublicensees permission to use your Submission in connection with the operation of their Internet businesses including, without limitation, the rights to: copy, distribute, transmit, publicly display, publicly perform, reproduce, edit, translate and reformat your Submission; and to publish your name in connection with your Submission.
            </p>
            <p>
                No compensation will be paid with respect to the use of your Submission, as provided herein. River Ridge Band Boosters is under no obligation to post or use any Submission you may provide and may remove any Submission at any time in River Ridge Band Boosters's sole discretion.
            </p>
            <p>
                By posting, uploading, inputting, providing or submitting your Submission you warrant and represent that you own or otherwise control all of the rights to your Submission as described in this section including, without limitation, all the rights necessary for you to provide, post, upload, input or submit the Submissions.
            </p>
            <h2>
                International Users
            </h2>
            <p>
                The Service is controlled, operated and administered by River Ridge Band Boosters from our offices within the USA. If you access the Service from a location outside the USA, you are responsible for compliance with all local laws. You agree that you will not use the River Ridge Band Boosters Content accessed through RRHS-Band.com in any country or in any manner prohibited by any applicable laws, restrictions or regulations.
            </p>
            <h2>
                Indemnification
            </h2>
            <p>
                You agree to indemnify, defend and hold harmless River Ridge Band Boosters, its officers, directors, employees, agents and third parties, for any losses, costs, liabilities and expenses (including reasonable attorney's fees) relating to or arising out of your use of or inability to use the Site or services, any user postings made by you, your violation of any terms of this Agreement or your violation of any rights of a third party, or your violation of any applicable laws, rules or regulations. River Ridge Band Boosters reserves the right, at its own cost, to assume the exclusive defense and control of any matter otherwise subject to indemnification by you, in which event you will fully cooperate with River Ridge Band Boosters in asserting any available defenses.
            </p>
            <h2>
                Liability Disclaimer
            </h2>
            <p>
                THE INFORMATION, SOFTWARE, PRODUCTS, AND SERVICES INCLUDED IN OR AVAILABLE THROUGH THE SITE MAY INCLUDE INACCURACIES OR TYPOGRAPHICAL ERRORS. CHANGES ARE PERIODICALLY ADDED TO THE INFORMATION HEREIN. RIVER RIDGE BAND BOOSTERS, INC. AND/OR ITS SUPPLIERS MAY MAKE IMPROVEMENTS AND/OR CHANGES IN THE SITE AT ANY TIME.
            </p>
            <p>
                RIVER RIDGE BAND BOOSTERS, INC. AND/OR ITS SUPPLIERS MAKE NO REPRESENTATIONS ABOUT THE SUITABILITY, RELIABILITY, AVAILABILITY, TIMELINESS, AND ACCURACY OF THE INFORMATION, SOFTWARE, PRODUCTS, SERVICES AND RELATED GRAPHICS CONTAINED ON THE SITE FOR ANY PURPOSE. TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, ALL SUCH INFORMATION, SOFTWARE, PRODUCTS, SERVICES AND RELATED GRAPHICS ARE PROVIDED "AS IS" WITHOUT WARRANTY OR CONDITION OF ANY KIND. RIVER RIDGE BAND BOOSTERS, INC. AND/OR ITS SUPPLIERS HEREBY DISCLAIM ALL WARRANTIES AND CONDITIONS WITH REGARD TO THIS INFORMATION, SOFTWARE, PRODUCTS, SERVICES AND RELATED GRAPHICS, INCLUDING ALL IMPLIED WARRANTIES OR CONDITIONS OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE AND NON-INFRINGEMENT.
            </p>
            <p>
                TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT SHALL RIVER RIDGE BAND BOOSTERS, INC. AND/OR ITS SUPPLIERS BE LIABLE FOR ANY DIRECT, INDIRECT, PUNITIVE, INCIDENTAL, SPECIAL, CONSEQUENTIAL DAMAGES OR ANY DAMAGES WHATSOEVER INCLUDING, WITHOUT LIMITATION, DAMAGES FOR LOSS OF USE, DATA OR PROFITS, ARISING OUT OF OR IN ANY WAY CONNECTED WITH THE USE OR PERFORMANCE OF THE SITE, WITH THE DELAY OR INABILITY TO USE THE SITE OR RELATED SERVICES, THE PROVISION OF OR FAILURE TO PROVIDE SERVICES, OR FOR ANY INFORMATION, SOFTWARE, PRODUCTS, SERVICES AND RELATED GRAPHICS OBTAINED THROUGH THE SITE, OR OTHERWISE ARISING OUT OF THE USE OF THE SITE, WHETHER BASED ON CONTRACT, TORT, NEGLIGENCE, STRICT LIABILITY OR OTHERWISE, EVEN IF RIVER RIDGE BAND BOOSTERS, INC. OR ANY OF ITS SUPPLIERS HAS BEEN ADVISED OF THE POSSIBILITY OF DAMAGES. BECAUSE SOME STATES/JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR LIMITATION OF LIABILITY FOR CONSEQUENTIAL OR INCIDENTAL DAMAGES, THE ABOVE LIMITATION MAY NOT APPLY TO YOU. IF YOU ARE DISSATISFIED WITH ANY PORTION OF THE SITE, OR WITH ANY OF THESE TERMS OF USE, YOUR SOLE AND EXCLUSIVE REMEDY IS TO DISCONTINUE USING THE SITE.
            </p>
            <h2>
                Termination/Access Restriction
            </h2>
            <p>
                River Ridge Band Boosters reserves the right, in its sole discretion, to terminate your access to the Site and the related services or any portion thereof at any time, without notice. To the maximum extent permitted by law, this agreement is governed by the laws of the State of Georgia and you hereby consent to the exclusive jurisdiction and venue of courts in Georgia in all disputes arising out of or relating to the use of the Site. Use of the Site is unauthorized in any jurisdiction that does not give effect to all provisions of these Terms, including, without limitation, this section.
            </p>
            <p>
                You agree that no joint venture, partnership, employment, or agency relationship exists between you and River Ridge Band Boosters as a result of this agreement or use of the Site. River Ridge Band Boosters's performance of this agreement is subject to existing laws and legal process, and nothing contained in this agreement is in derogation of River Ridge Band Boosters's right to comply with governmental, court and law enforcement requests or requirements relating to your use of the Site or information provided to or gathered by River Ridge Band Boosters with respect to such use. If any part of this agreement is determined to be invalid or unenforceable pursuant to applicable law including, but not limited to, the warranty disclaimers and liability limitations set forth above, then the invalid or unenforceable provision will be deemed superseded by a valid, enforceable provision that most closely matches the intent of the original provision and the remainder of the agreement shall continue in effect.
            </p>
            <p>
                Unless otherwise specified herein, this agreement constitutes the entire agreement between the user and River Ridge Band Boosters with respect to the Site and it supersedes all prior or contemporaneous communications and proposals, whether electronic, oral or written, between the user and River Ridge Band Boosters with respect to the Site. A printed version of this agreement and of any notice given in electronic form shall be admissible in judicial or administrative proceedings based upon or relating to this agreement to the same extent and subject to the same conditions as other business documents and records originally generated and maintained in printed form. It is the express wish to the parties that this agreement and all related documents be written in English.
            </p>
            <p>
            </p>
            <p>
            </p>
            <h2>
                Changes to Terms
            </h2>
            <p>
                River Ridge Band Boosters reserves the right, in its sole discretion, to change the Terms under which RRHS-Band.com is offered. The most current version of the Terms will supersede all previous versions. River Ridge Band Boosters encourages you to periodically review the Terms to stay informed of our updates.
            </p>
            <h2>
                Contact Us
            </h2>
            <p>
                River Ridge Band Boosters welcomes your questions or comments regarding the Terms:
            </p>
            <p>
                River Ridge Band Boosters, Inc.<br />
                400 Arnold Mill Road<br />
                Woodstock, Georgia 30188
            </p>
            <p>
                Email Address: <a href="mailto:Boosters@riverridgebands.org">Boosters@riverridgebands.org</a>
            </p>
            <p>
                Telephone number: <a href="tel:4044353086">(404) 435-3086</a>
            </p>
        </div>
    );
}
